import React, { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import '../styles/pages/_login.scss';
import { Input } from '../components';
import axios from 'axios';
import config from '../../config';
import Swal from 'sweetalert2';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  
  const handleLogin = (e) => {
    e.preventDefault();

    setLoading(true);

    axios.post(config.API_URL + '/admin/admins/login', {
      username: document.getElementById('username-input').value,
      password: document.getElementById('password-input').value
    })
    .then((response) => {
      if (response.data.ok) {
        localStorage.setItem('mb_token', response.data.data.token);
        localStorage.setItem('mb_admin', JSON.stringify(response.data.data.admin));
        
        window.location.href = '/panel';
      }
    })
    .catch((error) => {
      let error_message = '';
      if (error.response?.data.error) {
        error_message = error.response?.data.error;
      } else if (error.response?.data.message) {
        error_message = error.response?.data.message;
      } else {
        error_message = 'خطایی رخ داده است';
      }

      Swal.fire({
        icon: 'error',
        title: 'خطا',
        text: error_message,
        position: 'top-end',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
        toast: true
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };


  return (
    <div className="login-wrapper">
      <div className="login-content-wrapper">
        <div className="login-content">
          <h4 className="login-title">ورود به سیستم</h4>
          <div className="login-desc">لطفا اطلاعات خود را وارد کنید</div>

          <form className="login-form" action="" onSubmit={handleLogin}>
            <Input
              id="username-input"
              className=""
              type="username"
              placeholder="Username"
              value={username}
              onChange={setUsername}
            />
            <Input
              id="password-input"
              className="mt-4"
              type="password"
              placeholder="Password"
              value={password}
              onChange={setPassword}
            />

            <div className="login-button mt-4">
              <LoadingButton
                type="submit"
                variant="contained"
                fullWidth
                color='black'
                sx={{
                  boxShadow: 'none',
                  padding: '12px 0',
                  '&:hover': {
                    color: 'white', // Ensure the text color stays the same
                  },
                }}
                loading={loading}
              >
                ورود به سیستم
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>

      <div className="login-spacefiller">
        <img
          className="login-logo"
          src={require('../assets/images/logo.png')}
          alt="Logo"
        />
      </div>
    </div>
  );
}

export default Login;
