import { useCallback, useEffect, useState } from 'react';
import EventEmitter from '../../../simple-dash/utils/EventEmitter';
import Statistics from './Statistics';
import ViewsChart from './ViewsChart';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';
import ContentLoading from '../../../components/ContentLoading';

export default function Dashboard() {
  const [data, setData] = useState({
    total_users: 0,
    total_active_users: 0,
    total_musics: 0,
    total_artists: 0,
    total_albums: 0,
    daily_active_users_data: [],
  });
  const [loading, setLoading] = useState(true);

  const loadData = useCallback((q = '') => {
    setLoading(true);

    axios
      .get(config.API_URL + `/admin/general/dashboard`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
        },
      })
      .then((response) => {
        if (response.data.ok) {
          setData(response.data.data);
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    EventEmitter.emit('setPanelTitleText', 'داشبورد');
  });

  return (
    <div className="panel-content-container">
      {loading ? (
        <ContentLoading />
      ) : (
        <>
          <Statistics data={data} />

          <ViewsChart className="mt-4" data={data.daily_active_users_data} />
        </>
      )}
    </div>
  );
}
