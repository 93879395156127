import { useEffect, useState, useCallback } from 'react';
import EventEmitter from '../../../../simple-dash/utils/EventEmitter';
import axios from 'axios';
import Swal from 'sweetalert2';
import {
  TextField,
  Button,
  Box,
  Typography,
  Autocomplete,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { useDebounce } from '../../../../utils/hooks'; // Update the path based on your project structure
import config from '../../../../config'; // Update the path based on your project structure
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

export default function AddMusic() {
  useEffect(() => {
    EventEmitter.emit('setPanelTitleText', 'افزودن آهنگ');
  });

  const [inputData, setInputData] = useState({
    name: '',
    title: '',
    artist: '',
    lyrics: '',
    categories: [],
    artists: [],
    music_128: null,
    music_320: null,
    cover: null,
  });
  const [loading, setLoading] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [allArtists, setAllArtists] = useState([]);
  const [searchCategories, setSearchCategories] = useState('');
  const [searchArtists, setSearchArtists] = useState('');
  const debouncedSearchCategories = useDebounce(searchCategories, 500);
  const debouncedSearchArtists = useDebounce(searchArtists, 500);

  const handleCategoriesSearch = useCallback((q = '') => {
    axios
      .get(`${config.API_URL}/admin/music-categories?q=${q}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb_token')}`,
        },
      })
      .then((response) => {
        if (response.data.ok) {
          const current = inputData.categories;
          const newData = response.data.data.results;

          const merged = [
            ...new Map(
              [...current, ...newData].map((item) => [item.id, item])
            ).values(),
          ];

          setAllCategories(merged);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [inputData.categories]);

  const handleArtistsSearch = useCallback(
    (q = '') => {
      axios
        .get(`${config.API_URL}/admin/artists?q=${q}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb_token')}`,
          },
        })
        .then((response) => {
          if (response.data.ok) {
            const current = inputData.artists;
            const newData = response.data.data.results;

            const merged = [
              ...new Map(
                [...current, ...newData].map((item) => [item.id, item])
              ).values(),
            ];

            setAllArtists(merged);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [inputData.artists]
  );

  useEffect(() => {
    handleCategoriesSearch(debouncedSearchCategories);
  }, [debouncedSearchCategories, handleCategoriesSearch]);

  useEffect(() => {
    handleArtistsSearch(debouncedSearchArtists);
  }, [debouncedSearchArtists, handleArtistsSearch]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setInputData({ ...inputData, [name]: files[0] });
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const navigate = useNavigate();

  const handleAddMusic = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('name', inputData.name);
    formData.append('title', inputData.title);
    formData.append('artist', inputData.artist);
    formData.append('lyrics', inputData.lyrics);
    if (inputData.music_128) formData.append('music_128', inputData.music_128);
    if (inputData.music_320) formData.append('music_320', inputData.music_320);
    if (inputData.cover) formData.append('cover', inputData.cover);

    inputData.categories.forEach((category) => {
      formData.append('categories[]', category.id);
    });

    inputData.artists.forEach((artist) => {
      formData.append('artists[]', artist.id);
    });

    axios
      .post(`${config.API_URL}/admin/musics`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb_token')}`,
        },
      })
      .then((response) => {
        if (response.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'موفقیت',
            text: 'آهنگ با موفقیت افزوده شد.',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });

          // Reset form
          setInputData({
            name: '',
            title: '',
            artist: '',
            lyrics: '',
            categories: [],
            artists: [],
            music_128: null,
            music_320: null,
            cover: null,
          });

          // Redirect to musics list using react-router-dom
          navigate('/panel/musics');
        }
      })
      .catch((error) => {
        let error_message = 'خطایی رخ داده است';
        if (error.response?.data?.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data?.message) {
          error_message = error.response?.data.message;
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const steps = ['اطلاعات آهنگ', 'آپلود فایل‌ها'];

  return (
    <div className="panel-content-container">
      <Box
        sx={{
          maxWidth: 700,
          margin: 'auto',
          padding: 4,
          border: '1px solid #ccc',
          borderRadius: '8px',
          backgroundColor: '#fff',
        }}
      >
        <Typography variant="h5" component="div">
          افزودن آهنگ
        </Typography>
        <Stepper activeStep={activeStep} sx={{ my: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <>
            <Typography variant="body2" component="div" sx={{ mb: 2 }}>
              لطفا اطلاعات آهنگ را وارد کنید.
            </Typography>
            <TextField
              label="نام آهنگ"
              variant="outlined"
              fullWidth
              name="name"
              placeholder="مثال: Shayea - Asabani"
              value={inputData.name}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              label="عنوان آهنگ"
              variant="outlined"
              fullWidth
              name="title"
              placeholder="مثال: Asabani"
              value={inputData.title}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              label="عنوان خواننده"
              variant="outlined"
              fullWidth
              name="artist"
              placeholder="مثال: Shayea"
              value={inputData.artist}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <TextField
              label="متن آهنگ"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              name="lyrics"
              placeholder="متن آهنگ را وارد کنید"
              value={inputData.lyrics}
              onChange={handleInputChange}
              sx={{ mb: 2 }}
            />
            <Autocomplete
              multiple
              options={allCategories}
              getOptionLabel={(option) => option.title}
              filterOptions={(x) => x} // disable filtering
              filterSelectedOptions
              onChange={(event, newValue) => {
                setInputData({ ...inputData, categories: newValue });
              }}
              onInputChange={(event, newInputValue) => {
                setSearchCategories(newInputValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="دسته بندی ها"
                  placeholder="انتخاب کنید"
                  fullWidth
                />
              )}
              sx={{ mb: 2 }}
            />
            <Autocomplete
              multiple
              options={allArtists}
              getOptionLabel={(option) => option.name}
              filterOptions={(x) => x} // disable filtering
              filterSelectedOptions
              onChange={(event, newValue) => {
                setInputData({ ...inputData, artists: newValue });
              }}
              onInputChange={(event, newInputValue) => {
                setSearchArtists(newInputValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="خوانندگان"
                  placeholder="انتخاب کنید"
                  fullWidth
                />
              )}
              sx={{ mb: 2 }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
              <Button variant="contained" color="primary" onClick={handleNext}>
                بعدی
              </Button>
            </Box>
          </>
        )}

        {activeStep === 1 && (
          <>
            <Typography variant="body2" component="div" sx={{ mb: 2 }}>
              لطفا فایل‌های آهنگ را آپلود کنید.
            </Typography>
            <TextField
              type="file"
              label="آپلود آهنگ (128kbps)"
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="music_128"
              onChange={handleInputChange}
              sx={{ mb: 2 }}
              inputProps={{ accept: '.mp3' }} // only accept music files
            />
            <TextField
              type="file"
              label="آپلود آهنگ (320kbps)"
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="music_320"
              onChange={handleInputChange}
              sx={{ mb: 2 }}
              inputProps={{ accept: '.mp3' }} // only accept music files
            />
            <TextField
              type="file"
              label="آپلود کاور آهنگ"
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="cover"
              onChange={handleInputChange}
              sx={{ mb: 2 }}
              inputProps={{ accept: '.jpg, .jpeg, .png' }} // only accept image files
            />
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}
            >
              <Button variant="outlined" onClick={handleBack}>
                قبلی
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={handleAddMusic}
                loading={loading}
              >
                افزودن آهنگ
              </LoadingButton>
            </Box>
          </>
        )}
      </Box>
    </div>
  );
}
