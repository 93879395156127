import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    // check if user is logged in
    const token = localStorage.getItem('mb_token');
    if (token) {
      localStorage.removeItem('mb_token');
      localStorage.removeItem('mb_admin');
      navigate('/login');
    } else {
      navigate('/login');
    }
  });
}
