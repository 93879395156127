import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './pages/App';

//simple-dash styles
import "./simple-dash/styles/main.scss";
import "./styles/main.scss";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { grey } from '@mui/material/colors';

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontFamily: [
      'Yekan Bakh',
      'sans-serif'
    ].join(','),
  },
  palette: {
    'grey': {
      main: grey[800],
      light: grey[500],
      dark: grey[900],
      contrastText: '#fff',
    },
    'black': {
      main: '#000',
      light: '#333',
      dark: '#000',
      contrastText: '#fff',
    },
  }
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode> // this should be removed because it causes the components to render twice
    <BrowserRouter>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </CacheProvider>
    </BrowserRouter>
  //</React.StrictMode>
);

// make the project rtl
document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");