import { useCallback, useEffect, useState } from 'react';
import { Table } from '../../../simple-dash/components';
import { Button, ButtonGroup, Pagination } from '@mui/material';
import AddPromotedMusicModal from './AddPromotedMusicModal';
import DeletePromotedMusicModal from './DeletePromotedMusicModal';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';
import ContentLoading from '../../../components/ContentLoading';

export default function PromotedMusics() {
  const { id } = useParams();
  const [data, setData] = useState({
    meta: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 1,
    },
    results: [],
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(
    (q = '') => {
      setLoading(true);

      axios
        .get(`${config.API_URL}/admin/promoted-musics?page=${page}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        })
        .then((response) => {
          if (response.data.ok) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          let error_message = '';
          if (error.response?.data.error) {
            error_message = error.response?.data.error;
          } else if (error.response?.data.message) {
            error_message = error.response?.data.message;
          } else {
            error_message = 'خطایی رخ داده است';
          }

          Swal.fire({
            icon: 'error',
            title: 'خطا',
            text: error_message,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [page, id]
  );

  const [addPromotedMusicModalOpen, setAddPromotedMusicModalOpen] =
    useState(false);

  const [deletePromotedMusicModalOpen, setDeletePromotedMusicModalOpen] =
    useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="panel-content general-list">
      <div className="general-list-head">
        <div className="head-start">برگزیده ها</div>

        <div className="head-end">
          <Button
            className="add-btn"
            type="primary"
            onClick={() => setAddPromotedMusicModalOpen(true)}
          >
            افزودن آهنگ{' '}
            <i
              className="fa fa-plus me-2"
              style={{ verticalAlign: 'middle' }}
            ></i>
          </Button>
        </div>
      </div>

      {loading ? (
        <ContentLoading />
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>نام آهنگ</th>
                <th>عنوان آهنگ</th>
                <th>خواننده</th>
                <th>عملیات</th>
              </tr>
            </thead>

            <tbody>
              {data.results.map((item, i) => (
                <tr key={i}>
                  <td>{item.music_id}</td>
                  <td>{item.music?.name}</td>
                  <td>{item.music?.title}</td>
                  <td>{item.music?.artist}</td>
                  <td width={10}>
                    <ButtonGroup variant="outlined">
                      <Button
                        color="error"
                        onClick={() => {
                          setDeleteItemId(item.music_id);
                          setDeletePromotedMusicModalOpen(true);
                        }}
                      >
                        حذف
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <div className="general-list-footer">
        <Pagination
          color="primary"
          count={data.meta.lastPage}
          onChange={(event, page) => setPage(page)}
        />
      </div>

      <AddPromotedMusicModal
        show={addPromotedMusicModalOpen}
        onClose={() => setAddPromotedMusicModalOpen(false)}
        parentLoadData={loadData}
        albumId={id}
      />

      <DeletePromotedMusicModal
        show={deletePromotedMusicModalOpen}
        onClose={() => setDeletePromotedMusicModalOpen(false)}
        itemId={deleteItemId}
        parentLoadData={loadData}
        albumId={id}
      />
    </div>
  );
}
