import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Switch, FormControlLabel } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import ContentLoading from '../../../components/ContentLoading';
import config from '../../../config';

export default function BotOptions() {
  const [botOptions, setBotOptions] = useState({
    youtube_audio_enable: false,
    link_downloader_enable: false,
  });
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const loadData = useCallback(() => {
    setLoading(true);

    axios
      .get(config.API_URL + `/admin/settings/bot-options`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
        },
      })
      .then((response) => {
        if (response.data.ok) {
          const data = response.data.data;
          setBotOptions({
            youtube_audio_enable: data.youtube_audio_enable === '1',
            link_downloader_enable: data.link_downloader_enable === '1',
          });
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'An error occurred';
        }

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSaveChanges = () => {
    setSaveLoading(true);

    axios
      .post(
        config.API_URL + `/admin/settings/bot-options`,
        {
          youtube_audio_enable: botOptions.youtube_audio_enable ? '1' : '0',
          link_downloader_enable: botOptions.link_downloader_enable ? '1' : '0',
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        }
      )
      .then((response) => {
        if (response.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Bot options updated successfully!',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'An error occurred';
        }

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const handleToggleChange = (option) => (event) => {
    setBotOptions((prevOptions) => ({
      ...prevOptions,
      [option]: event.target.checked,
    }));
  };

  return (
    <div className="panel-content" style={{ maxWidth: 400 }}>
      <Typography variant="h5" component="div" sx={{ mb: 3 }}>
        امکانات ربات
      </Typography>

      {loading ? (
        <ContentLoading />
      ) : (
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={botOptions.youtube_audio_enable}
                onChange={handleToggleChange('youtube_audio_enable')}
              />
            }
            label="جستجوی آهنگ در یوتوب"
          />
          <FormControlLabel
            control={
              <Switch
                checked={botOptions.link_downloader_enable}
                onChange={handleToggleChange('link_downloader_enable')}
              />
            }
            label="دانلودر لینک"
          />

          <Box sx={{ textAlign: 'right', mt: 2 }}>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={saveLoading}
              onClick={handleSaveChanges}
            >
              ثبت تغییرات
            </LoadingButton>
          </Box>
        </Box>
      )}
    </div>
  );
}
