import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const panelWrapper = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const admin = JSON.parse(localStorage.getItem('mb_admin'));
      const token = localStorage.getItem('mb_token');
      if (!admin || !token) {
        navigate('/login');
        return;
      }

      if (window.location.pathname === '/panel') {
        navigate('/panel/dashboard');
      }

      setIsLoading(false);
    }, [navigate]);

    // Optionally show a loading indicator while checking the token
    if (isLoading) {
      return <div>Loading...</div>;
    }

    // Render the wrapped component if token is valid
    return <WrappedComponent {...props} />;
  };
};

export default panelWrapper;
