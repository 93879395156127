import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Grid,
  Typography,
  Pagination,
} from '@mui/material';
import AddAdModal from './AddAdModal';
import EditAdModal from './EditAdModal';
import DeleteAdModal from './DeleteAdModal';
import Swal from 'sweetalert2';
import axios from 'axios';
import config from '../../../config';
import ContentLoading from '../../../components/ContentLoading';

export default function ManageAds() {
  const [data, setData] = useState({
    meta: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 1,
    },
    results: [],
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [addAdModalOpen, setAddAdModalOpen] = useState(false);

  const [editAdModalOpen, setEditAdModalOpen] = useState(false);
  const [editItemData, setEditItemData] = useState(null);

  const [deleteAdModalOpen, setDeleteAdModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const loadData = useCallback(
    (q = '') => {
      setLoading(true);
      var url = config.API_URL + `/admin/ads?page=${page}`;
      if (q !== '') {
        url += `&q=${q}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        })
        .then((response) => {
          if (response.data.ok) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          let error_message = '';
          if (error.response?.data.error) {
            error_message = error.response?.data.error;
          } else if (error.response?.data.message) {
            error_message = error.response?.data.message;
          } else {
            error_message = 'خطایی رخ داده است';
          }

          Swal.fire({
            icon: 'error',
            title: 'خطا',
            text: error_message,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [page]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="panel-content general-list">
      <div className="general-list-head">
        <div className="head-start">تبلیغات</div>

        <div className="head-end">
          <Button
            className="add-btn"
            type="primary"
            onClick={() => setAddAdModalOpen(true)}
          >
            افزودن آیتم{' '}
            <i
              className="fa fa-plus me-2"
              style={{ verticalAlign: 'middle' }}
            ></i>
          </Button>
        </div>
      </div>

      {loading ? (
        <ContentLoading />
      ) : (
        <>
          <Grid container spacing={3}>
            {data.results.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Card>
                  <CardMedia
                    component="img"
                    height="150"
                    image={
                      item.image?.url ||
                      require('../../../assets/default-image.jpg')
                    }
                    alt={item.text}
                  />
                  <CardContent>
                    <Typography
                      component="div"
                      sx={{
                        height: 150,
                        overflowY: 'auto',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                      }}
                    >
                      {item.text}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setEditItemData(item);
                        setEditAdModalOpen(true);
                      }}
                    >
                      <i className="fa fa-edit"></i>
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => {
                        setDeleteItemId(item.id);
                        setDeleteAdModalOpen(true);
                      }}
                    >
                      <i className="fa fa-trash"></i>
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <div className="general-list-footer">
        <Pagination
          color="primary"
          count={data.meta.lastPage}
          onChange={(event, page) => setPage(page)}
        />
      </div>

      <AddAdModal
        show={addAdModalOpen}
        onClose={() => setAddAdModalOpen(false)}
        parentLoadData={loadData}
      />

      <EditAdModal
        show={editAdModalOpen}
        onClose={() => setEditAdModalOpen(false)}
        parentLoadData={loadData}
        editItemData={editItemData}
      />

      <DeleteAdModal
        show={deleteAdModalOpen}
        onClose={() => setDeleteAdModalOpen(false)}
        itemId={deleteItemId}
        parentLoadData={loadData}
      />
    </div>
  );
}
