import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  IconButton,
  Pagination,
  TextField,
} from '@mui/material';
import { Table } from '../../../simple-dash/components';
import AddPlaylistModal from './AddPlaylistModal';
import DeletePlaylistModal from './DeletePlaylistModal';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';
import ContentLoading from '../../../components/ContentLoading';
import EditPlaylistModal from './EditPlaylistModal';

export default function PlaylistsTab() {
  const [data, setData] = useState({
    meta: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 1,
    },
    results: [],
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const [addPlaylistModalOpen, setAddPlaylistModalOpen] = useState(false);

  const [editPlaylistModalOpen, setEditPlaylistModalOpen] = useState(false);
  const [editItemData, setEditItemData] = useState(null);

  const [deletePlaylistModalOpen, setDeletePlaylistModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const loadData = useCallback(
    (q = '') => {
      setLoading(true);

      axios
        .get(config.API_URL + `/admin/playlists?page=${page}&q=${q}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        })
        .then((response) => {
          if (response.data.ok) {
            var temp = response.data.data;

            temp.results = temp.results.map((item) => {
              item.categories_imploded = item.categories
                .map((category) => category.name)
                .join(', ');
              return item;
            });

            setData(temp);
          }
        })
        .catch((error) => {
          let error_message = '';
          if (error.response?.data.error) {
            error_message = error.response?.data.error;
          } else if (error.response?.data.message) {
            error_message = error.response?.data.message;
          } else {
            error_message = 'خطایی رخ داده است';
          }

          Swal.fire({
            icon: 'error',
            title: 'خطا',
            text: error_message,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [page]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="panel-content general-list">
      <div className="general-list-head">
        <div className="head-start">پلی لیست ها</div>
        <div className="head-end">
          <Button
            className="add-btn"
            type="primary"
            onClick={() => setAddPlaylistModalOpen(true)}
          >
            افزودن پلی لیست{' '}
            <i
              className="fa fa-plus me-2"
              style={{ verticalAlign: 'middle' }}
            ></i>
          </Button>
        </div>
      </div>

      <div className="mb-5" style={{ maxWidth: 300 }}>
        <TextField
          label="جستجو"
          variant="outlined"
          fullWidth
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={() => loadData(search)}>
                <i className="fa fa-search"></i>
              </IconButton>
            ),
          }}
        />
      </div>

      {loading ? (
        <ContentLoading />
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>نام پلی لیست</th>
                <th>دسته بندی</th>
                <th>تعداد آهنگ</th>
                <th>عملیات</th>
              </tr>
            </thead>
            <tbody>
              {data.results.map((item, i) => (
                <tr key={i}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.categories_imploded}</td>
                  <td>{item.musics_count}</td>
                  <td width={10}>
                    <ButtonGroup variant="outlined">
                      <NavLink to={`/panel/playlists/${item.id}/musics`}>
                        <Button color="primary">آهنگ ها</Button>
                      </NavLink>

                      <Button
                        color="grey"
                        onClick={() => {
                          setEditItemData(item);
                          setEditPlaylistModalOpen(true);
                        }}
                      >
                        ویرایش
                      </Button>

                      <Button
                        color="error"
                        onClick={() => {
                          setDeleteItemId(item.id);
                          setDeletePlaylistModalOpen(true);
                        }
                        }
                      >
                        حذف
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <div className="general-list-footer">
        <Pagination
          color="primary"
          count={data.meta.lastPage}
          onChange={(event, page) => setPage(page)}
        />
      </div>

      <AddPlaylistModal
        show={addPlaylistModalOpen}
        onClose={() => setAddPlaylistModalOpen(false)}
        parentLoadData={loadData}
      />

      <EditPlaylistModal
        show={editPlaylistModalOpen}
        onClose={() => setEditPlaylistModalOpen(false)}
        editItemData={editItemData}
        parentLoadData={loadData}
      />

      <DeletePlaylistModal
        show={deletePlaylistModalOpen}
        onClose={() => setDeletePlaylistModalOpen(false)}
        itemId={deleteItemId}
        parentLoadData={loadData}
      />
    </div>
  );
}
