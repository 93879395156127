import React, { useCallback, useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import ContentLoading from '../../../components/ContentLoading';

export default function AdsCooldownTime({className}) {
  const [adsCooldownTime, setAdsCooldownTime] = useState('');
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const loadData = useCallback(() => {
    setLoading(true);

    axios
      .get(config.API_URL + `/admin/settings/ads-cooldown-time`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
        },
      })
      .then((response) => {
        if (response.data.ok) {
          setAdsCooldownTime(response.data.data.ads_cooldown_time);
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSaveChanges = () => {
    setSaveLoading(true);

    axios
      .post(
        config.API_URL + `/admin/settings/ads-cooldown-time`,
        { ads_cooldown_time: adsCooldownTime },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        }
      )
      .then((response) => {
        if (response.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'موفق',
            text: 'تنظیمات با موفقیت ثبت شد!',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  return (
    <div className={`panel-content ${className || ''}`} style={{ maxWidth: 400 }}>
      <Typography variant="h5" component="div" sx={{ mb: 3 }}>
        فاصله زمانی تبلیغات
      </Typography>

      {loading ? (
        <ContentLoading />
      ) : (
        <Box>
          <TextField
            label="زمان فاصله بین تبلیغات (به دقیقه)"
            variant="outlined"
            value={adsCooldownTime}
            onChange={(e) => setAdsCooldownTime(e.target.value)}
            fullWidth
          />

          <Box sx={{ textAlign: 'right', mt: 2 }}>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={saveLoading}
              onClick={handleSaveChanges}
            >
              ثبت تغییرات
            </LoadingButton>
          </Box>
        </Box>
      )}
    </div>
  );
}
