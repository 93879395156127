import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const navigate = useNavigate();

  useEffect(() => {
    // check if user is logged in
    const token = localStorage.getItem('mb_token');
    if (token) {
      navigate('/panel/dashboard');
    } else {
      navigate('/login');
    }
  });
}
