import { Route, Routes } from 'react-router-dom';

import Home from './Home';

import Login from '../simple-dash/pages/Login';
import Logout from './Logout';

import PanelLayout from '../simple-dash/pages/layouts/PanelLayout';
import Components from '../simple-dash/pages/panel/components';

import Dashboard from './panel/dashboard';
import Settings from './panel/settings';

import Musics from './panel/musics/Musics';
import AddMusic from './panel/musics/add-music/AddMusic';

import Albums from './panel/albums/Albums';
import AlbumMusics from './panel/albums/album-musics/AlbumMusics';

import Artists from './panel/artists/Artists';

import Categories from './panel/categories/Categories';

import Playlists from './panel/playlists/Playlists';
import PlaylistMusics from './panel/playlists/playlist-musics/PlaylistMusics';

import ManageAds from './panel/ads/ManageAds';

import Users from './panel/users/Users';
import BotSettings from './panel/bot-settings/BotSettings';

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/panel/*" element={<PanelLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />

          <Route path="musics" element={<Musics />} />
          <Route path="musics/add-music" element={<AddMusic />} />

          <Route path="albums" element={<Albums />} />
          <Route path="albums/:id/musics" element={<AlbumMusics />} />

          <Route path="artists" element={<Artists />} />

          <Route path="music-categories" element={<Categories />} />

          <Route path="playlists" element={<Playlists />} />
          <Route path="playlists/:id/musics" element={<PlaylistMusics />} />

          <Route path="manage-ads" element={<ManageAds />} />

          <Route path="users" element={<Users />} />

          {/* <Route path="components" element={<Components />} /> */}

          <Route path="bot-settings" element={<BotSettings />} />

          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    </>
  );
}
