import { useEffect } from 'react';
import EventEmitter from '../../../simple-dash/utils/EventEmitter';
import ChangePassword from './ChangePassword';

export default function Settings() {
  useEffect(() => {
    EventEmitter.emit('setPanelTitleText', 'تنظیمات');
  }, []);

  return (
    <>
      <div className="panel-content-container">
        <ChangePassword />
      </div>
    </>
  );
}
