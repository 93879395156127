import { useCallback, useEffect, useState } from 'react';
import EventEmitter from '../../../simple-dash/utils/EventEmitter';
import { Table } from '../../../simple-dash/components';
import {
  Button,
  ButtonGroup,
  IconButton,
  Pagination,
  TextField,
} from '@mui/material';
import ViewMusicModal from './ViewMusicModal';
import { NavLink } from 'react-router-dom';
import DeleteMusicModal from './DeleteMusicModal';
import EditMusicModal from './EditMusicModal';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';
import ContentLoading from '../../../components/ContentLoading';

export default function Musics() {
  const [data, setData] = useState({
    meta: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 1,
    },
    results: [],
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const [viewMusicModalOpen, setViewMusicModalOpen] = useState(false);
  const [viewItemData, setViewItemData] = useState(null);

  const [editMusicModalOpen, setEditMusicModalOpen] = useState(false);
  const [editItemData, setEditItemData] = useState(null);

  const [deleteMusicModalOpen, setDeleteMusicModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const loadData = useCallback(
    (q = '') => {
      setLoading(true);

      axios
        .get(config.API_URL + `/admin/musics?page=${page}&q=${q}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        })
        .then((response) => {
          if (response.data.ok) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          let error_message = '';
          if (error.response?.data.error) {
            error_message = error.response?.data.error;
          } else if (error.response?.data.message) {
            error_message = error.response?.data.message;
          } else {
            error_message = 'خطایی رخ داده است';
          }

          Swal.fire({
            icon: 'error',
            title: 'خطا',
            text: error_message,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [page]
  );

  useEffect(() => {
    EventEmitter.emit('setPanelTitleText', 'مدیریت آهنگ ها');
  });

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="panel-content-container">
      <div className="panel-content general-list">
        <div className="general-list-head">
          <div className="head-start">آهنگ ها</div>

          <div className="head-end">
            <NavLink to="/panel/musics/add-music">
              <Button className="add-btn" type="primary">
                افزودن آهنگ{' '}
                <i
                  className="fa fa-plus me-2"
                  style={{ verticalAlign: 'middle' }}
                ></i>
              </Button>
            </NavLink>
          </div>
        </div>

        <div className="mb-5" style={{ maxWidth: 300 }}>
          <TextField
            label="جستجو"
            variant="outlined"
            fullWidth
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton size="small" onClick={() => loadData(search)}>
                  <i className="fa fa-search"></i>
                </IconButton>
              ),
            }}
          />
        </div>

        {loading ? (
          <ContentLoading />
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>نام آهنگ</th>
                  <th>نام خواننده</th>
                  <th>عنوان آهنگ</th>
                  <th>تاریخ انتشار</th>
                  <th>تعداد دانلود</th>
                  <th>عملیات</th>
                </tr>
              </thead>

              <tbody>
                {data.results.map((item, i) => (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.artist}</td>
                    <td>{item.title}</td>
                    <td dir='ltr' style={{textAlign: "right"}}>{new Date(item.created_at).toLocaleString('fa-IR', {timeZone: 'Asia/Tehran'})}</td>
                    <td>{item.total_downloads}</td>
                    <td width={10}>
                      <ButtonGroup variant="outlined">
                        <Button
                          onClick={() => {
                            setViewItemData(item);
                            setViewMusicModalOpen(true);
                          }}
                        >
                          مشاهده
                        </Button>

                        <Button
                          color="grey"
                          onClick={() => {
                            setEditItemData(item);
                            setEditMusicModalOpen(true);
                          }}
                        >
                          ویرایش
                        </Button>

                        <Button
                          color="error"
                          onClick={() => {
                            setDeleteItemId(item.id);
                            setDeleteMusicModalOpen(true);
                          }}
                        >
                          حذف
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}

        <div className="general-list-footer">
          <Pagination
            color="primary"
            count={data.meta.lastPage}
            onChange={(event, page) => setPage(page)}
          />
        </div>

        <ViewMusicModal
          show={viewMusicModalOpen}
          onClose={() => setViewMusicModalOpen(false)}
          viewItemData={viewItemData}
        />

        <EditMusicModal
          show={editMusicModalOpen}
          onClose={() => setEditMusicModalOpen(false)}
          editItemData={editItemData}
          parentLoadData={loadData}
        />

        <DeleteMusicModal
          show={deleteMusicModalOpen}
          onClose={() => setDeleteMusicModalOpen(false)}
          itemId={deleteItemId}
          parentLoadData={loadData}
        />
      </div>
    </div>
  );
}
