import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  IconButton,
  Pagination,
  TextField,
} from '@mui/material';
import { Table } from '../../../simple-dash/components';
import AddCategoryModal from './AddCategoryModal';
import EditCategoryModal from './EditCategoryModal';
import DeleteCategoryModal from './DeleteCategoryModal';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';
import ContentLoading from '../../../components/ContentLoading';

export default function CategoriesTab() {
  const [data, setData] = useState({
    meta: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 1,
    },
    results: [],
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const [addCategoryModalOpen, setAddCategoryModalOpen] = useState(false);

  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false);
  const [editItemData, setEditItemData] = useState(null);

  const [deleteCategoryModalOpen, setDeleteCategoryModalOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  const loadData = useCallback(
    (q = '') => {
      setLoading(true);

      axios
        .get(
          config.API_URL + `/admin/playlist-categories?page=${page}&q=${q}`,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
            },
          }
        )
        .then((response) => {
          if (response.data.ok) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          let error_message = '';
          if (error.response?.data.error) {
            error_message = error.response?.data.error;
          } else if (error.response?.data.message) {
            error_message = error.response?.data.message;
          } else {
            error_message = 'خطایی رخ داده است';
          }

          Swal.fire({
            icon: 'error',
            title: 'خطا',
            text: error_message,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [page]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="panel-content general-list">
      <div className="general-list-head">
        <div className="head-start">دسته بندی ها</div>
        <div className="head-end">
          <Button
            className="add-btn"
            type="primary"
            onClick={() => setAddCategoryModalOpen(true)}
          >
            افزودن دسته بندی{' '}
            <i
              className="fa fa-plus me-2"
              style={{ verticalAlign: 'middle' }}
            ></i>
          </Button>
        </div>
      </div>

      <div className="mb-5" style={{ maxWidth: 300 }}>
        <TextField
          label="جستجو"
          variant="outlined"
          fullWidth
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={() => loadData(search)}>
                <i className="fa fa-search"></i>
              </IconButton>
            ),
          }}
        />
      </div>

      {loading ? (
        <ContentLoading />
      ) : (
        <>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>نام دسته بندی</th>
                <th>تعداد پلی لیست</th>
                <th>عملیات</th>
              </tr>
            </thead>
            <tbody>
              {data.results.map((item, i) => (
                <tr key={i}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.playlists_count}</td>
                  <td width={10}>
                    <ButtonGroup variant="outlined">
                      <Button
                        color="grey"
                        onClick={() => {
                          setEditItemData(item);
                          setEditCategoryModalOpen(true);
                        }}
                      >
                        ویرایش
                      </Button>
                      <Button
                        color="error"
                        onClick={() => {
                          setDeleteCategoryId(item.id);
                          setDeleteCategoryModalOpen(true);
                        }}
                      >
                        حذف
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      <div className="general-list-footer">
        <Pagination
          color="primary"
          count={data.meta.lastPage}
          onChange={(event, page) => setPage(page)}
        />
      </div>

      <AddCategoryModal
        show={addCategoryModalOpen}
        onClose={() => setAddCategoryModalOpen(false)}
        parentLoadData={loadData}
      />

      <EditCategoryModal
        show={editCategoryModalOpen}
        onClose={() => setEditCategoryModalOpen(false)}
        parentLoadData={loadData}
        editItemData={editItemData}
      />

      <DeleteCategoryModal
        show={deleteCategoryModalOpen}
        onClose={() => setDeleteCategoryModalOpen(false)}
        itemId={deleteCategoryId}
        parentLoadData={loadData}
      />
    </div>
  );
}
