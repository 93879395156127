import React, { useState, useEffect } from 'react';
import {
  Tabs,
  Tab,
  Box,
} from '@mui/material';
import EventEmitter from '../../../simple-dash/utils/EventEmitter';
import AdsTab from './AdsTab';
import PromotedMusicsTab from './PromotedMusicsTab';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function Playlists() {
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    EventEmitter.emit('setPanelTitleText', 'مدیریت تبلیغات');
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="panel-content-container">
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="playlist and category tabs">
        <Tab label="تبلیغات" />
        <Tab label="آهنگ های برگزیده" />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        <AdsTab />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <PromotedMusicsTab />
      </TabPanel>
    </div>
  );
}
