import { Box, Button, Modal, Typography } from '@mui/material';
import './ViewMusicModal.scss';
import { useEffect, useState } from 'react';

export default function ViewMusicModal({ show, onClose, viewItemData }) {
  const [implodedArtists, setImplodedArtists] = useState('');
  useEffect(() => {
    if (viewItemData) {
      var implodedArtists = viewItemData.artists
        .map((artist) => artist.name)
        .join(', ');

      setImplodedArtists(implodedArtists);
    }
  }, [viewItemData]);

  const [implodedAlbums, setImplodedAlbums] = useState('');
  useEffect(() => {
    if (viewItemData) {
      var implodedAlbums = viewItemData.albums
        .map((album) => album.name)
        .join(', ');

      setImplodedAlbums(implodedAlbums);
    }
  }, [viewItemData]);

  const [implodedCategories, setImplodedCategories] = useState('');
  useEffect(() => {
    if (viewItemData) {
      var implodedCategories = viewItemData.categories
        .map((category) => category.title)
        .join(', ');

      setImplodedCategories(implodedCategories);
    }
  }, [viewItemData]);

  return (
    <Modal open={show} onClose={onClose}>
      {!viewItemData ? (
        <Box
          className="album-info"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 700,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 4,
            p: 4,
          }}
        >
          <Typography variant="h4" id="modal-modal-title">
            Loading...
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            className="music-info"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
              maxWidth: 700,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 4,
              p: 4,
              maxHeight: '90%',
              overflowY: 'auto',
            }}
          >
            <div className="row" dir="ltr">
              <div className="col-xl-4 col-12">
                <img
                  className="music-image"
                  src={
                    viewItemData.cover?.url ||
                    require('../../../assets/default-cover.jpg')
                  }
                  alt="Music Album Cover"
                />
              </div>

              <div className="col-12 col-xl-8 mt-5 mt-xl-0">
                <Typography variant="h4" id="music-title" dir="ltr">
                  {viewItemData.name}
                </Typography>

                <Typography variant="h6" className="mt-2">
                  Artists: {implodedArtists}
                </Typography>

                <Typography variant="h6" className="mt-2">
                  Album: {implodedAlbums}
                </Typography>

                <Typography variant="h6" className="mt-2">
                  Categories: {implodedCategories}
                </Typography>

                {viewItemData.file_128 && (
                  <Button
                    variant="contained"
                    className="mt-3"
                    href={viewItemData.file_128?.url}
                    target="_blank"
                  >
                    Download 128
                  </Button>
                )}

                {viewItemData.file_320 && (
                  <Button
                    variant="contained"
                    className="mt-3 ms-3"
                    href={viewItemData.file_320?.url}
                    target="_blank"
                  >
                    Download 320
                  </Button>
                )}
              </div>
            </div>

            <div className="music-lyrics mt-5">
              <Typography variant="h6">متن آهنگ</Typography>
              <Typography
                className="music-lyrics-content mt-3"
                style={{ whiteSpace: 'pre-line' }}
              >
                {viewItemData.lyrics}
              </Typography>
            </div>
          </Box>
        </>
      )}
    </Modal>
  );
}
