import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';

// Styled components
const DeleteButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const CloseButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.grey[400],
  },
}));

// DeleteAdModal component
export default function DeleteAdModal({ show, onClose, itemId, parentLoadData }) {
  const [loading, setLoading] = useState(false);

  // Handle delete request
  const handleDelete = () => {
    setLoading(true);

    axios
      .delete(`http://localhost:8000/api/admin/ads/${itemId}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'موفقیت',
            text: 'تبلیغ با موفقیت حذف شد.',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });

          // Refresh data and close modal
          parentLoadData();
          onClose();
        }
      })
      .catch((error) => {
        let error_message = 'خطایی رخ داده است';
        if (error.response?.data?.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data?.message) {
          error_message = error.response?.data.message;
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={show} onClose={onClose}>
      <DialogTitle id="alert-dialog-title" sx={{ color: '#f44336' }}>
        آیا از حذف این تبلیغ اطمینان دارید؟
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          این عملیات غیر قابل بازگشت است.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CloseButton onClick={onClose} disabled={loading}>بستن</CloseButton>
        <DeleteButton onClick={handleDelete} loading={loading} autoFocus>
          حذف
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
}
