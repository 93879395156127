import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import config from '../../../config';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';

export default function AddCategoryModal({ show, onClose, parentLoadData }) {
  const [inputData, setInputData] = useState({
    name: '',
  });
  const [loading, setLoading] = useState(false);

  const handleAddData = () => {
    setLoading(true);

    axios
      .post(config.API_URL + `/admin/playlist-categories`, {
        name: inputData.name,
      }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
        },
      })
      .then((response) => {
        if (response.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'موفقیت',
            text: 'دسته بندی با موفقیت افزوده شد.',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });

          setInputData({ name: '' });
          parentLoadData();
          onClose();
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        className="category-info"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}
      >
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          افزودن دسته بندی
        </Typography>

        <Typography variant="body2" component="div" sx={{ mb: 2 }}>
          لطفا اطلاعات دسته بندی را وارد کنید.
        </Typography>

        <TextField
          label="نام دسته بندی"
          variant="outlined"
          fullWidth
          placeholder="مثال: ایرانی"
          sx={{ mb: 2 }}
          value={inputData.name}
          onChange={(e) => setInputData({ ...inputData, name: e.target.value })}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleAddData}
          >
            افزودن دسته بندی
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
