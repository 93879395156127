import { useEffect } from 'react';
import EventEmitter from '../../../simple-dash/utils/EventEmitter';
import ForceJoinTelegramChannels from './ForceJoinTelegramChannels';
import AdsCooldownTime from './AdsCooldownTime';
import BotOptions from './BotOptions';

export default function BotSettings() {
  useEffect(() => {
    EventEmitter.emit('setPanelTitleText', 'تنظیمات');
  }, []);

  return (
    <>
      <div className="panel-content-container">
        <BotOptions />

        <AdsCooldownTime className="mt-4" />

        <ForceJoinTelegramChannels className="mt-4" />
      </div>
    </>
  );
}
