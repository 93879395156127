import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import config from '../../../config';
import { useDebounce } from '../../../utils/hooks';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';

export default function AddPlaylistModal({ show, onClose, parentLoadData }) {
  const [inputData, setInputData] = useState({
    name: '',
    categories: [],
  });

  const [allCategories, setAllCategories] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const handleCategoriesSearch = useCallback(
    (q = '') => {
      axios
        .get(config.API_URL + `/admin/playlist-categories?q=${q}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        })
        .then((response) => {
          if (response.data.ok) {
            // current selected categories should be always included in the list
            const current = inputData.categories;
            const newData = response.data.data.results;

            // merge the two arrays and remove duplicates
            const merged = [
              ...new Map([...current, ...newData].map((item) => [item.id, item])).values(),
            ];

            setAllCategories(merged);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [inputData.categories]
  );

  useEffect(() => {
    handleCategoriesSearch(debouncedSearch);
  }, [debouncedSearch, handleCategoriesSearch]);

  const [loading, setLoading] = useState(false);

  const handleAddData = () => {
    setLoading(true);

    axios
      .post(config.API_URL + `/admin/playlists`, {
        name: inputData.name,
        categories: inputData.categories.map((category) => category.id),
      }, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
        },
      })
      .then((response) => {
        if (response.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'موفقیت',
            text: 'دسته بندی با موفقیت افزوده شد.',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });

          setInputData({ name: '' });
          parentLoadData();
          onClose();
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        className="category-info"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}
      >
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          افزودن پلی لیست
        </Typography>

        <Typography variant="body2" component="div" sx={{ mb: 2 }}>
          لطفا اطلاعات پلی لیست را وارد کنید.
        </Typography>
        <TextField
          label="نام پلی لیست"
          variant="outlined"
          fullWidth
          placeholder="مثال: ورزشی"
          sx={{ mb: 2 }}
          value={inputData.name}
          onChange={(event) => {
            setInputData({ ...inputData, name: event.target.value });
          }}
        />

        <Autocomplete
          multiple
          id="categories"
          options={allCategories}
          getOptionLabel={(option) => option.name}
          filterOptions={(x) => x} // disable filtering
          filterSelectedOptions
          onChange={(event, newValue) => {
            setInputData({ ...inputData, categories: newValue });
          }}
          onInputChange={(event, newInputValue) => {
            setSearch(newInputValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="دسته بندی ها"
              placeholder="انتخاب کنید"
              fullWidth
            />
          )}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <LoadingButton variant="contained" color="primary" loading={loading} onClick={handleAddData}>
            افزودن پلی لیست
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
