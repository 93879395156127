import React, { useState, useEffect, useCallback } from 'react';
import '../../styles/layout/_sidebar.scss';
import { NavLink } from 'react-router-dom';
import { Avatar } from '../../components';
import EventEmitter from '../../utils/EventEmitter';

function Sidebar({ dark }) {
  const [sidebarVisible, setSidebarVisible] = useState({
    desktop: true,
    mobile: false,
  });

  const toggleClass = (element, className, condition) => {
    element.classList.toggle(className, condition);
  };

  const handleSidebarVisibility = useCallback(() => {
    const sidebarElement = document.querySelector('.sidebar');
    const backdropElement = document.querySelector('.sidebar-backdrop');
    const isMobile = window.innerWidth <= 990;

    toggleClass(
      sidebarElement,
      'show',
      isMobile ? sidebarVisible.mobile : sidebarVisible.desktop
    );
    if (isMobile) {
      toggleClass(backdropElement, 'show', sidebarVisible.mobile);
    }
  }, [sidebarVisible]);

  const toggleSidebar = () => {
    const isMobile = window.innerWidth <= 990;
    setSidebarVisible((prevState) => ({
      desktop: isMobile ? prevState.desktop : !prevState.desktop,
      mobile: isMobile ? !prevState.mobile : prevState.mobile,
    }));
  };

  useEffect(() => {
    EventEmitter.addListener('openSidebar', () => toggleSidebar(true));
    EventEmitter.addListener('closeSidebar', () => toggleSidebar(false));
    EventEmitter.addListener('toggleSidebar', toggleSidebar);

    window.addEventListener('resize', handleSidebarVisibility);
    handleSidebarVisibility();

    return () => {
      window.removeEventListener('resize', handleSidebarVisibility);
    };
  }, [handleSidebarVisibility]);

  useEffect(() => {
    handleSidebarVisibility();
  }, [sidebarVisible, handleSidebarVisibility]);

  return (
    <div className="sidebar-wrapper">
      <div className={`sidebar ${dark ? 'dark' : ''}`}>
        <div className="logo-wrapper">
          <div className="sidebar-logo">
            <img src={require('../../assets/images/logo.png')} alt="Logo" />
          </div>
        </div>

        <div className="sidebar-top">
          <ul className="sidebar-nav">
            {[
              {
                path: '/panel/dashboard',
                icon: 'fas fa-tachometer-alt',
                text: 'داشبورد',
              },
              {
                path: '/panel/musics',
                icon: 'fas fa-music',
                text: 'مدیریت آهنگ ها',
              },
              {
                path: '/panel/albums',
                icon: 'fas fa-album-collection',
                text: 'مدیریت آلبوم ها',
              },
              {
                path: '/panel/artists',
                icon: 'fas fa-microphone-alt',
                text: 'مدیریت خواننده ها',
              },
              {
                path: '/panel/music-categories',
                icon: 'fas fa-layer-group',
                text: 'دسته بندی آهنگ ها',
              },
              {
                path: '/panel/playlists',
                icon: 'fas fa-list-music',
                text: 'پلی لیست ها',
              },
            ].map((item, index) => (
              <li key={index}>
                <NavLink to={item.path} activeclassname="active">
                  <i className={`nav-icon ${item.icon}`}></i>
                  <span className="nav-text">{item.text}</span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>

        <div className="sidebar-bottom">
          <ul className="sidebar-nav">
            {[
              {
                path: '/panel/manage-ads',
                icon: 'fas fa-bullhorn', // Suitable for managing advertisements
                text: 'مدیریت تبلیغات',
              },
              {
                path: '/panel/users',
                icon: 'fas fa-users', // Represents user management
                text: 'مدیریت کاربران',
              },
              {
                // bot settings
                path: '/panel/bot-settings',
                icon: 'fas fa-robot', // Represents bot settings
                text: 'تنظیمات ربات',
              },
              {
                path: '/panel/settings',
                icon: 'fas fa-cog', // Commonly used for settings
                text: 'تنظیمات',
              },
            ].map((item, index) => (
              <li key={index}>
                <NavLink to={item.path} activeclassname="active">
                  <i className={`nav-icon ${item.icon}`}></i>
                  <span className="nav-text">{item.text}</span>
                </NavLink>
              </li>
            ))}
          </ul>

          <div className="sidebar-account">
            <div className="sidebar-account-img">
              <Avatar>
                <img
                  src={
                    JSON.parse(localStorage.getItem('mb_admin')).profile ||
                    require('../../../assets/default-user.jpg')
                  }
                  alt="Avatar"
                />
              </Avatar>
            </div>

            <div className="sidebar-account-text">
              <div className="sidebar-account-name">
                <span>
                  {JSON.parse(localStorage.getItem('mb_admin')).last_name ? (
                    <>
                      {JSON.parse(localStorage.getItem('mb_admin')).first_name}{' '}
                      {JSON.parse(localStorage.getItem('mb_admin')).last_name}
                    </>
                  ) : (
                    JSON.parse(localStorage.getItem('mb_admin')).first_name
                  )}
                </span>
              </div>
              <div className="sidebar-account-desc">
                <span>مدیر سایت</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar-backdrop" onClick={toggleSidebar}></div>
    </div>
  );
}

export default Sidebar;
