import { useCallback, useEffect, useState } from 'react';
import EventEmitter from '../../../../simple-dash/utils/EventEmitter';
import { Table } from '../../../../simple-dash/components';
import { Button, ButtonGroup, Pagination } from '@mui/material';
import AddAlbumMusicModal from './AddAlbumMusicModal';
import DeleteAlbumMusicModal from './DeleteAlbumMusicModal';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import config from '../../../../config';
import Swal from 'sweetalert2';
import ContentLoading from '../../../../components/ContentLoading';

export default function AlbumMusics() {
  const { id } = useParams();
  const [data, setData] = useState({
    meta: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 1,
    },
    results: [],
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(
    (q = '') => {
      setLoading(true);

      axios
        .get(`${config.API_URL}/admin/albums/${id}/musics?page=${page}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        })
        .then((response) => {
          if (response.data.ok) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          let error_message = '';
          if (error.response?.data.error) {
            error_message = error.response?.data.error;
          } else if (error.response?.data.message) {
            error_message = error.response?.data.message;
          } else {
            error_message = 'خطایی رخ داده است';
          }

          Swal.fire({
            icon: 'error',
            title: 'خطا',
            text: error_message,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [page, id]
  );

  const [addAlbumMusicModalOpen, setAddAlbumMusicModalOpen] =
    useState(false);

  const [deleteAlbumMusicModalOpen, setDeleteAlbumMusicModalOpen] =
    useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    EventEmitter.emit('setPanelTitleText', 'مدیریت آلبوم ها');
  });

  return (
    <div className="panel-content-container">
      <div className="panel-content general-list">
        <div className="general-list-head">
          <div className="head-start">آهنگ های آلبوم</div>

          <div className="head-end">
            <Button
              className="add-btn"
              type="primary"
              onClick={() => setAddAlbumMusicModalOpen(true)}
            >
              افزودن آهنگ{' '}
              <i
                className="fa fa-plus me-2"
                style={{ verticalAlign: 'middle' }}
              ></i>
            </Button>
          </div>
        </div>

        {loading ? (
          <ContentLoading />
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>نام آهنگ</th>
                  <th>عنوان آهنگ</th>
                  <th>خواننده</th>
                  <th>عملیات</th>
                </tr>
              </thead>

              <tbody>
                {data.results.map((item, i) => (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.title}</td>
                    <td>{item.artist}</td>
                    <td width={10}>
                      <ButtonGroup variant="outlined">
                        <Button
                          color="error"
                          onClick={() => {
                            setDeleteItemId(item.id);
                            setDeleteAlbumMusicModalOpen(true);
                          }}
                        >
                          حذف
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}

        <div className="general-list-footer">
          <Pagination
            color="primary"
            count={data.meta.lastPage}
            onChange={(event, page) => setPage(page)}
          />
        </div>

        <AddAlbumMusicModal
          show={addAlbumMusicModalOpen}
          onClose={() => setAddAlbumMusicModalOpen(false)}
          parentLoadData={loadData}
          albumId={id}
        />

        <DeleteAlbumMusicModal
          show={deleteAlbumMusicModalOpen}
          onClose={() => setDeleteAlbumMusicModalOpen(false)}
          itemId={deleteItemId}
          parentLoadData={loadData}
          albumId={id}
        />
      </div>
    </div>
  );
}
