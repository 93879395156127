import { StatisticBox } from '../../../simple-dash/components';

export default function Statistics({data}) {
  return (
    <div className="row gy-4">
      <div className="col-12 col-md-6 col-lg-3">
        <StatisticBox
          className="bg-blue fg-white"
          title="کاربران فعال امروز"
          icon="fas fa-users"
          amount={data.total_active_users}
          link="/panel/users"
        />
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <StatisticBox
          className="bg-orange fg-white"
          title="تعداد آهنگ ها"
          icon="fas fa-music"
          amount={data.total_musics}
          link="/panel/musics"
        />
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <StatisticBox
          className="bg-green fg-white"
          title="تعداد خواننده ها"
          icon="fas fa-microphone-alt"
          amount={data.total_artists}
          link="/panel/artists"
        />
      </div>
      <div className="col-12 col-md-6 col-lg-3">
        <StatisticBox
          className="bg-purple fg-white"
          title="تعداد آلبوم ها"
          icon="fas fa-compact-disc"
          amount={data.total_albums}
          link="/panel/albums"
        />
      </div>
    </div>
  );
}
