import { Box, Button, Modal, Typography } from '@mui/material';
import './ViewAlbumModal.scss';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function ViewAlbumModal({ show, onClose, viewItemData }) {
  const [implodedArtists, setImplodedArtists] = useState('');

  useEffect(() => {
    if (viewItemData) {
      var implodedArtists = viewItemData.artists
        .map((artist) => artist.name)
        .join(', ');

      setImplodedArtists(implodedArtists);
    }
  }, [viewItemData]);

  return (
    <Modal open={show} onClose={onClose}>
      {!viewItemData ? (
        <Box
          className="album-info"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 700,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 4,
            p: 4,
          }}
        >
          <Typography variant="h4" id="modal-modal-title">
            Loading...
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            className="album-info"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '90%',
              maxWidth: 700,
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: 4,
              p: 4,
            }}
          >
            <div className="row" dir="ltr">
              <div className="col-xl-4 col-12">
                <img
                  className="album-image"
                  src={
                    viewItemData.cover?.url ||
                    require('../../../assets/default-cover.jpg')
                  }
                  alt="Album Album Cover"
                />
              </div>

              <div className="col-12 col-xl-8 mt-5 mt-xl-0">
                <Typography variant="h4" id="album-title" dir="ltr">
                  {viewItemData.name}
                </Typography>

                <Typography variant="h6" className="mt-2">
                  Artists: {implodedArtists}
                </Typography>

                <Typography variant="h6" className="mt-2">
                  Musics Count: {viewItemData.musics_count}
                </Typography>

                <NavLink to={`/panel/albums/${viewItemData.id}/musics`}>
                  <Button variant="contained" className="mt-3">
                    آهنگ های آلبوم
                  </Button>
                </NavLink>
              </div>
            </div>
          </Box>
        </>
      )}
    </Modal>
  );
}
