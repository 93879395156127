import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import config from '../../../config';
import { useDebounce } from '../../../utils/hooks';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';

export default function AddPromotedMusicModal({
  show,
  onClose,
  parentLoadData,
  albumId,
}) {
  const [selectedMusic, setSelectedMusic] = useState(null);
  const [allMusics, setAllMusics] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const handleMusicSearch = useCallback((q = '') => {
    axios
      .get(config.API_URL + `/admin/musics?q=${q}`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
        },
      })
      .then((response) => {
        if (response.data.ok) {
          setAllMusics(response.data.data.results);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    handleMusicSearch(debouncedSearch);
  }, [debouncedSearch, handleMusicSearch]);

  const [loading, setLoading] = useState(false);

  const handleAddMusic = () => {
    if (!selectedMusic) {
      Swal.fire({
        icon: 'error',
        title: 'خطا',
        text: 'لطفا یک آهنگ انتخاب کنید',
        position: 'top-end',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
        toast: true,
      });
      return;
    }

    setLoading(true);

    axios
      .post(
        config.API_URL + `/admin/promoted-musics`,
        {
          music: selectedMusic.id,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        }
      )
      .then((response) => {
        if (response.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'موفقیت',
            text: 'آهنگ با موفقیت افزوده شد.',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });

          setSelectedMusic(null);
          parentLoadData();
          onClose();
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        className="music-info"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}
      >
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          افزودن آهنگ برگزیده
        </Typography>

        <Typography variant="body2" component="div" sx={{ mb: 2 }}>
          لطفا آهنگ مورد نظر را جستجو و انتخاب کنید.
        </Typography>

        <Autocomplete
          id="music"
          options={allMusics}
          getOptionLabel={(option) => option.name}
          filterOptions={(x) => x} // disable filtering
          onChange={(event, newValue) => {
            setSelectedMusic(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setSearch(newInputValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="آهنگ"
              placeholder="جستجو و انتخاب کنید"
              fullWidth
            />
          )}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleAddMusic}
          >
            افزودن آهنگ
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
