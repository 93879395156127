import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Grid } from '@mui/material';
import ContentLoading from '../../../components/ContentLoading';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';

export default function ForceJoinTelegramChannels({ className }) {
  const [channels, setChannels] = useState([
    {
      channel_name: '',
      channel_id: '',
      channel_join_text: '',
      channel_join_btn: '',
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const loadData = useCallback(() => {
    setLoading(true);

    axios
      .get(config.API_URL + `/admin/settings/force-join-channels`, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
        },
      })
      .then((response) => {
        if (response.data.ok) {
          let force_join_channels = response.data.data.force_join_channels;
          if (force_join_channels.length === 0) {
            setChannels([
              {
                channel_name: '',
                channel_id: '',
                channel_join_text: '',
                channel_join_btn: '',
              },
            ]);
            return;
          }

          setChannels(force_join_channels);
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleAddChannel = () => {
    setChannels([
      ...channels,
      {
        channel_name: '',
        channel_id: '',
        channel_join_text: '',
        channel_join_btn: '',
      },
    ]);
  };

  const handleChannelChange = (index, field, value) => {
    const newChannels = [...channels];
    newChannels[index][field] = value;
    setChannels(newChannels);
  };

  const handleSaveChanges = () => {
    setSaveLoading(true);

    axios
      .post(
        config.API_URL + `/admin/settings/force-join-channels`,
        { force_join_channels: channels },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        }
      )
      .then((response) => {
        if (response.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'موفق',
            text: 'تغییرات با موفقیت ثبت شد!',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  return (
    
    <div className={`panel-content ${className || ''}`} style={{ maxWidth: 800 }}>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" component="div">
          جوین اجباری
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          onClick={handleAddChannel}
          disabled={loading || saveLoading}
        >
          افزودن کانال <i className="fa fa-plus" style={{ marginRight: 8 }}></i>
        </Button>
      </Box>

      <Typography variant="body1" component="div" sx={{ mb: 3 }}>
        کاربران جهت استفاده از ربات باید در این کانال‌ها جوین باشند.
      </Typography>

      {loading ? (
        <ContentLoading />
      ) : (
        <>
          <Grid container spacing={2}>
            {channels.map((channel, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Box
                  sx={{
                    mb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                  }}
                >
                  <TextField
                    label="نام کانال"
                    variant="outlined"
                    value={channel.channel_name}
                    onChange={(e) =>
                      handleChannelChange(index, 'channel_name', e.target.value)
                    }
                    fullWidth
                  />
                  <TextField
                    label="یوزرنیم / شناسه کانال"
                    variant="outlined"
                    value={channel.channel_id}
                    onChange={(e) =>
                      handleChannelChange(index, 'channel_id', e.target.value)
                    }
                    fullWidth
                  />
                  <TextField
                    label="متن جوین کانال"
                    variant="outlined"
                    value={channel.channel_join_text}
                    multiline
                    rows={4}
                    onChange={(e) =>
                      handleChannelChange(
                        index,
                        'channel_join_text',
                        e.target.value
                      )
                    }
                    fullWidth
                  />
                  <TextField
                    label="دکمه جوین"
                    variant="outlined"
                    value={channel.channel_join_btn}
                    onChange={(e) =>
                      handleChannelChange(
                        index,
                        'channel_join_btn',
                        e.target.value
                      )
                    }
                    fullWidth
                  />
                </Box>
              </Grid>
            ))}
          </Grid>

          <Box sx={{ textAlign: 'right', mt: 2 }}>
            <LoadingButton
              variant="contained"
              color="primary"
              className="change-pass-btn"
              loading={saveLoading}
              onClick={handleSaveChanges}
            >
              ثبت تغییرات
            </LoadingButton>
          </Box>
        </>
      )}
    </div>
  );
}
