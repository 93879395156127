import {
  Autocomplete,
  Box,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { LoadingButton } from '@mui/lab';
import config from '../../../config'; // Update the path based on your project structure
import { useDebounce } from '../../../utils/hooks'; // Update the path based on your project structure

export default function EditAlbumModal({ show, onClose, parentLoadData, editItemData }) {
  const [inputData, setInputData] = useState({
    name: '',
    artist: '',
    artists: [],
    cover: null,
  });

  const [allArtists, setAllArtists] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (editItemData) {
      // Populate the form with the data received from the parent
      setInputData({
        name: editItemData.name,
        artist: editItemData.artist,
        artists: editItemData.artists || [],
        cover: null,
      });
    }
  }, [editItemData]);

  const handleArtistsSearch = useCallback(
    (q = '') => {
      axios
        .get(config.API_URL + `/admin/artists?q=${q}`, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        })
        .then((response) => {
          if (response.data.ok) {
            const current = inputData.artists;
            const newData = response.data.data.results;

            const merged = [
              ...new Map(
                [...current, ...newData].map((item) => [item.id, item])
              ).values(),
            ];

            setAllArtists(merged);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [inputData.artists]
  );

  useEffect(() => {
    handleArtistsSearch(debouncedSearch);
  }, [debouncedSearch, handleArtistsSearch]);

  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'cover') {
      setInputData({ ...inputData, cover: files[0] });
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handeUpdateAlbum = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('name', inputData.name);
    formData.append('artist', inputData.artist);
    if (inputData.cover) {
      formData.append('cover', inputData.cover);
    }
    inputData.artists.forEach((artist) => {
      formData.append('artists[]', artist.id);
    });

    axios
      .post(`${config.API_URL}/admin/albums/${editItemData.id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb_token')}`,
        },
      })
      .then((response) => {
        if (response.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'موفقیت',
            text: 'آلبوم با موفقیت ویرایش شد.',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });

          setInputData({ name: '', artist: '', artists: [], cover: null });
          parentLoadData();
          onClose();
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data?.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data?.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        className="album-info"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}
      >
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          ویرایش
        </Typography>

        <Typography variant="body2" component="div" sx={{ mb: 2 }}>
          از این قسمت می توانید آلبوم مورد نظر خود را ویرایش کنید.
        </Typography>
        <TextField
          label="نام آلبوم"
          variant="outlined"
          fullWidth
          name="name"
          placeholder="مثال: Asabani"
          value={inputData.name}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />
        <TextField
          label="خواننده"
          variant="outlined"
          fullWidth
          name="artist"
          placeholder="مثال: Shayea"
          value={inputData.artist}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />
        <Autocomplete
          multiple
          options={allArtists}
          getOptionLabel={(option) => option.name}
          filterOptions={(x) => x} // disable filtering
          filterSelectedOptions
          onChange={(event, newValue) => {
            setInputData({ ...inputData, artists: newValue });
          }}
          onInputChange={(event, newInputValue) => {
            setSearch(newInputValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={inputData.artists}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="خوانندگان"
              placeholder="خوانندگان را انتخاب کنید"
              fullWidth
            />
          )}
          sx={{ mb: 2 }}
        />
        <TextField
          type="file"
          label="کاور آلبوم"
          InputLabelProps={{ shrink: true }}
          fullWidth
          name="cover"
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          inputProps={{ accept: '.jpg, .jpeg, .png' }} // only accept image files
          helperText="اگر نمی خواهید کاور را تغییر دهید، این بخش را خالی بگذارید."
        />
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handeUpdateAlbum}
          >
            ویرایش آلبوم
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
