import {
  Box,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import Swal from 'sweetalert2';
import config from '../../../config';
import { LoadingButton } from '@mui/lab';

export default function AddAdModal({ show, onClose, parentLoadData }) {
  const [inputData, setInputData] = useState({
    text: '',
    image: null,
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image') {
      setInputData({ ...inputData, image: files[0] });
    } else {
      setInputData({ ...inputData, [name]: value });
    }
  };

  const handleAddAd = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('text', inputData.text);
    if (inputData.image) {
      formData.append('image', inputData.image);
    }

    axios
      .post(`${config.API_URL}/admin/ads`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb_token')}`,
        },
      })
      .then((response) => {
        if (response.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'موفقیت',
            text: 'تبلیغ با موفقیت افزوده شد.',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });

          setInputData({ text: '', image: null });
          parentLoadData();
          onClose();
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data?.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data?.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal open={show} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 4,
          p: 4,
        }}
      >
        <Typography variant="h5" component="div" sx={{ mb: 2 }}>
          افزودن تبلیغ
        </Typography>

        <Typography variant="body2" component="div" sx={{ mb: 2 }}>
          لطفا اطلاعات تبلیغ را وارد کنید.
        </Typography>

        <TextField
          label="متن تبلیغ"
          variant="outlined"
          fullWidth
          name="text"
          placeholder="مثال: محصول فوق العاده برای سلامت شما"
          multiline
          rows={4}
          value={inputData.text}
          onChange={handleInputChange}
          sx={{ mb: 2 }}
        />

        <TextField
          type="file"
          label="تصویر تبلیغ"
          variant="outlined"
          fullWidth
          name="image"
          onChange={handleInputChange}
          sx={{ mb: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ accept: '.jpg, .jpeg, .png' }} // only accept image files
        />

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loading}
            onClick={handleAddAd}
          >
            افزودن تبلیغ
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}
