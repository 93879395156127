import React, { useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import Swal from 'sweetalert2';
import config from '../../../config';

function ChangePassword({ className, ...props }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'خطا',
        text: 'رمز عبور جدید و تکرار آن مطابقت ندارند',
        position: 'top-end',
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
        toast: true,
      });
      return;
    }

    setLoading(true);

    axios
      .post(
        config.API_URL + '/admin/admins/change-password',
        {
          old_password: currentPassword,
          new_password: newPassword,
          confirm_password: confirmPassword,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        }
      )
      .then((response) => {
        if (response.data.ok) {
          Swal.fire({
            icon: 'success',
            title: 'موفق',
            text: 'رمز عبور با موفقیت تغییر کرد!',
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        }
      })
      .catch((error) => {
        let error_message = '';
        if (error.response?.data.error) {
          error_message = error.response?.data.error;
        } else if (error.response?.data.message) {
          error_message = error.response?.data.message;
        } else {
          error_message = 'خطایی رخ داده است';
        }

        Swal.fire({
          icon: 'error',
          title: 'خطا',
          text: error_message,
          position: 'top-end',
          timer: 3000,
          timerProgressBar: true,
          showConfirmButton: false,
          toast: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      className={`panel-content change-password ${className || ''}`}
      style={{ maxWidth: 800 }}
      {...props}
    >
      <Box className="change-password-head" sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" component="div">
          تغییر رمز عبور
        </Typography>
      </Box>

      <Box component="form">
        <Box sx={{ mb: 3 }}>
          <TextField
            type="password"
            id="current-pass-input"
            label="رمز عبور فعلی"
            variant="outlined"
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </Box>

        <Box sx={{ mb: 3 }}>
          <TextField
            type="password"
            id="new-pass-input"
            label="رمز عبور جدید"
            variant="outlined"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Box>

        <Box sx={{ mb: 4 }}>
          <TextField
            type="password"
            id="repeat-pass-input"
            label="تکرار رمز عبور جدید"
            variant="outlined"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Box>

        <Box sx={{ textAlign: 'right' }}>
          <LoadingButton
            variant="contained"
            color="primary"
            className="change-pass-btn"
            loading={loading}
            onClick={handleChangePassword}
          >
            تغییر رمز عبور
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ChangePassword;
