import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

const ViewsChart = ({ className, data }) => {
  if (!data || data.length === 0) {
    return (
      <div className={`panel-content${className ? ` ${className}` : ''}`}>
        <p className='mb-4'>کاربران فعال این ماه</p>
        <p>No data available</p>
      </div>
    );
  }

  const chartData = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: 'Active Users',
        data: data.map((item) => item.count),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legends
      },
      title: {
        display: false, // Hide title as well, since it's shown separately
      },
    },
    scales: {
      x: {
        title: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className={`panel-content${className ? ` ${className}` : ''}`}>
      <p className='mb-4'>کاربران فعال این ماه</p>
      <div className="views-chart">
        <Line data={chartData} options={options} width={"100%"} height={400} />
      </div>
    </div>
  );
};

export default ViewsChart;
