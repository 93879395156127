import { useCallback, useEffect, useState } from 'react';
import EventEmitter from '../../../simple-dash/utils/EventEmitter';
import { Table } from '../../../simple-dash/components';
import { IconButton, Pagination, Switch, TextField } from '@mui/material';
import axios from 'axios';
import config from '../../../config';
import Swal from 'sweetalert2';
import ContentLoading from '../../../components/ContentLoading';

export default function Users() {
  const [data, setData] = useState({
    meta: {
      total: 0,
      perPage: 10,
      currentPage: 1,
      lastPage: 1,
    },
    results: [],
  });
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(
    (q = '') => {
      setLoading(true);
      var url = config.API_URL + `/admin/users?page=${page}`;
      if (q !== '') {
        url += `&q=${q}`;
      }

      axios
        .get(url, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('mb_token'),
          },
        })
        .then((response) => {
          if (response.data.ok) {
            setData(response.data.data);
          }
        })
        .catch((error) => {
          let error_message = '';
          if (error.response?.data.error) {
            error_message = error.response?.data.error;
          } else if (error.response?.data.message) {
            error_message = error.response?.data.message;
          } else {
            error_message = 'خطایی رخ داده است';
          }

          Swal.fire({
            icon: 'error',
            title: 'خطا',
            text: error_message,
            position: 'top-end',
            timer: 3000,
            timerProgressBar: true,
            showConfirmButton: false,
            toast: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [page]
  );

  useEffect(() => {
    EventEmitter.emit('setPanelTitleText', 'مدیریت کاربران');
  });

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="panel-content-container">
      <div className="panel-content general-list">
        <div className="general-list-head">
          <div className="head-start">کاربران</div>

          <div className="head-end"></div>
        </div>

        <div className="mb-5" style={{ maxWidth: 300 }}>
          <TextField
            label="جستجو"
            variant="outlined"
            fullWidth
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton size="small" onClick={() => loadData(search)}>
                  <i className="fa fa-search"></i>
                </IconButton>
              ),
            }}
          />
        </div>

        {loading ? (
          <ContentLoading />
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>شناسه تلگرام</th>
                  <th>نام و نام خانوادگی</th>
                  <th>یوزرنیم</th>
                  <th>تاریخ عضویت</th>
                  <th>آخرین فعالیت</th>
                  <th>فعال / غیرفعال</th>
                </tr>
              </thead>

              <tbody>
                {data.results.map((item, i) => (
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>{item.telegram_id}</td>
                    <td>{item.last_name ? item.first_name + ' ' + item.last_name : item.first_name}</td>
                    <td dir="ltr" style={{ textAlign: 'right' }}>
                      @{item.username}
                    </td>
                    <td dir='ltr' style={{textAlign: "right"}}>{new Date(item.created_at).toLocaleString('fa-IR', {timeZone: 'Asia/Tehran'})}</td>
                    <td dir='ltr' style={{textAlign: "right"}}>{new Date(item.last_active_at).toLocaleString('fa-IR', {timeZone: 'Asia/Tehran'})}</td>
                    <td>
                      <Switch
                        color="primary"
                        checked={item.status === 'active'}
                        onChange={(event) => {
                          // Update user status
                          data.results[i].status = event.target.checked
                            ? 'active'
                            : 'inactive';
                          setData({ ...data });

                          // Send request to server
                          axios
                            .post(
                              config.API_URL + '/admin/users/' + item.id,
                              {
                                status: event.target.checked
                                  ? 'active'
                                  : 'inactive',
                              },
                              {
                                headers: {
                                  Authorization:
                                    'Bearer ' +
                                    localStorage.getItem('mb_token'),
                                },
                              }
                            )
                            .then((response) => {
                              if (response.data.ok) {
                                Swal.fire({
                                  icon: 'success',
                                  title: 'انجام شد',
                                  text: 'وضعیت کاربر با موفقیت تغییر یافت!',
                                  position: 'top-end',
                                  timer: 3000,
                                  timerProgressBar: true,
                                  showConfirmButton: false,
                                  toast: true,
                                });
                              }
                            })
                            .catch((error) => {
                              let error_message = '';
                              if (error.response?.data.error) {
                                error_message = error.response?.data.error;
                              } else if (error.response?.data.message) {
                                error_message = error.response?.data.message;
                              } else {
                                error_message = 'خطایی رخ داده است';
                              }

                              Swal.fire({
                                icon: 'error',
                                title: 'خطا',
                                text: error_message,
                                position: 'top-end',
                                timer: 3000,
                                timerProgressBar: true,
                                showConfirmButton: false,
                                toast: true,
                              });
                            });
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}

        <div className="general-list-footer">
          <Pagination
            color="primary"
            count={data.meta.lastPage}
            onChange={(event, page) => setPage(page)}
          />
        </div>
      </div>
    </div>
  );
}
